@import "./variables";
@import "./_fonts";
@import "../designer/_components";
@import "./_extensions";

/****** GENERAL ******/
html {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
  margin: 0;
}

h2 {
  font-weight: 500;
}

*:focus {
  outline-color: transparent !important;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

.error-directive {
  font-size: 12px;
  color: red;
  line-height: 18px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.xhr-error {
  font-size: 12px;
  color: red;
  margin: 3px 0;
}

.error {
  color: red;
}

.readonly {
  cursor: default;
  pointer-events: none;
}

label.required:after {
  content: "*";
  margin-left: 5px;
}

/****** PRIME-NG ******/
.p-field {
  margin-bottom: 0;
}

label,
.p-multiselect-label {
  color: $primaryTextColor;
}

.p-toolbar {
  border: none;
  background: none;
  margin-bottom: 5px;
  padding: 0.85rem 0;

  h2 {
    margin-right: 20px;
  }
}

.p-col-divider-left {
  border-left: 2px solid $highlightBgDark;
}

.p-col-divider-right {
  border-right: 2px solid $highlightBgDark;
}

.p-selectbutton .p-button {
  @extend .p-button-outlined;
}

.field.grid {
  label {
    height: 30px;
  }
}

.p-datatable {
  .p-datatable-tbody > tr > td {
    border-width: 0px 0px 1px 0px;
  }

  &.p-datatable-sm .p-datatable-header {
    padding: 0.85rem 0;
  }

  tr {
    &.selected {
      font-weight: bold;
    }
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    input {
      width: 100%;
    }

    .p-button-success {
      color: green !important;
    }

    .p-button-danger {
      color: red !important;
    }
  }

  .p-datatable-header {
    .p-input-icon-left {
      margin-right: 10px;
    }
  }

  .p-datatable-loading-overlay {
    background-color: transparent;
  }
}

.p-datepicker {
  &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background-color: transparent !important;
  }

  table td > span.p-highlight {
    background-color: grey !important;
    color: white !important;
  }
}

.pi-pin {
  background-image: url("/assets/pin.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
}

.pi-unlink {
  background-image: url("/assets/link.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
}

.pi-link {
  background-image: url("/assets/unlink.svg");
  height: 15px;
  width: 15px;
  background-size: contain;
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.text-red {
  color: red;
}

.text-alternate-gray-50:nth-child(odd) {
  background-color: var(--gray-50);
}

.text-alternate-gray-80:nth-child(odd) {
  background-color: #eee;
}

.text-alternate-gray-100:nth-child(odd) {
  background-color: var(--gray-100);
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.bg-gray-80 {
  background-color: #eee;
}

.w-full .p-dropdown {
  width: 100% !important;
}
