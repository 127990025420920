.p-chips {
  .p-chips-multiple-container {
    padding: calc(nth($inputPadding, 1) / 2) nth($inputPadding, 2);

    &:not(.p-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
      @include focused-input();
    }

    .p-chips-token {
      padding: calc(nth($inputPadding, 1) / 2) nth($inputPadding, 2);
      margin-right: $inlineSpacing;
      background: $highlightBg;
      color: $highlightTextColor;
      border-radius: $borderRadius;

      .p-chips-token-icon {
        margin-left: $inlineSpacing;
      }
    }

    .p-chips-input-token {
      padding: calc(nth($inputPadding, 1) / 2) 0;

      input {
        font-family: $fontFamily;
        font-size: $fontSize;
        color: $textColor;
        padding: 0;
        margin: 0;
      }
    }
  }

  &.p-error > .p-inputtext,
  &.p-invalid > .p-inputtext {
    @include invalid-input();
  }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  @include invalid-input();
}
